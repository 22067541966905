import TemplateCustomerFileTableProps from './TemplateCustomerFileTable';
import TemplateCustomerFileForm from './templateForm/TemplateCustomerFileForm';
import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';
import { AccountingConnectorType } from '../../../../../../src/types/accountingConnector';
import { CustomerFileType } from '../../../../../../types/customerFile';
import { SettingCustomerFileTemplateType } from '../../../../types/settingTemplate';

type TemplatesProps = {
	connectorCode?: AccountingConnectorType['code'];
	customerFileId?: CustomerFileType['id'];
};

const Templates = ({ connectorCode, customerFileId }: TemplatesProps) => {
	const { push } = useSettingsDialog();

	const handleTemplateSelection = (selectedTemplate?: SettingCustomerFileTemplateType) => {
		push(
			<TemplateCustomerFileForm
				connectorCode={connectorCode}
				customerFileId={customerFileId}
				selectedTemplate={selectedTemplate}
			/>,
		);
	};

	return (
		<SettingsDialogPage title="Modèles de pièces">
			<TemplateCustomerFileTableProps customerFileId={customerFileId} onTemplateSelection={handleTemplateSelection} />
		</SettingsDialogPage>
	);
};

export default Templates;
