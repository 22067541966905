import { useQuery } from '@apollo/client';
import { useCallback } from 'react';

import modules from '../../modules/modules';
import { ModuleType } from '../../types/module';
import { ModuleCodeEnum } from '../../types/widget';
import { FETCH_MODULES, FetchModulesType } from '../api/module';

const GENERAL_MODULE = {
	code: ModuleCodeEnum.GENERAL,
	name: 'Général',
};

const useModulesDataSource = () => {
	const { data: modulesFetchedData } = useQuery<FetchModulesType>(FETCH_MODULES);

	const dataSource = useCallback(
		(
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: Pick<ModuleType, 'code' | 'name'>[];
			count: number;
		}> => {
			if (!modulesFetchedData?.modules || modulesFetchedData.modules.length === 0) {
				return Promise.resolve({
					items: [GENERAL_MODULE],
					count: 1,
				});
			}

			let items = modulesFetchedData.modules.flatMap(({ code }) => {
				if (code === ModuleCodeEnum.GENERAL) {
					return [GENERAL_MODULE];
				}

				const moduleConfig = modules.find(({ code: moduleCode }) => moduleCode === code);

				if (!moduleConfig) return [];

				const { name: moduleName } = moduleConfig;

				return [
					{
						name: moduleName,
						code,
					},
				];
			});

			if (search) {
				items = items.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));
			}

			return Promise.resolve({
				items: items.slice(offset, offset + limit),
				count: items.length,
			});
		},
		[modulesFetchedData?.modules],
	);

	return { dataSource };
};

export default useModulesDataSource;
