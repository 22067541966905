import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import {
	FETCH_ACCOUNTING_CONNECTORS,
	FetchAccountingConnectorsResultType,
	FetchAccountingConnectorsVariablesType,
} from '../../../api/accountingConnector';

const useAccountingConnectorsDataSource = () => {
	const [fetchAccountingConnectors] = useLazyQuery<
		FetchAccountingConnectorsResultType,
		FetchAccountingConnectorsVariablesType
	>(FETCH_ACCOUNTING_CONNECTORS);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: { code: string | null; name: string }[];
		}> => {
			const { data, error } = await fetchAccountingConnectors({
				variables: {
					page: { limit, offset },
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				accountingConnectors: { count = 0, items = [] },
			} = data ?? {
				accountingConnectors: {},
			};

			const returnedItems: { code: string | null; name: string }[] = items;
			const returnedCount = count + 1;

			if (offset === 0) {
				returnedItems.unshift({ code: null, name: 'Aucun' });
			}

			return {
				count: returnedCount,
				items: returnedItems,
			};
		},
		[fetchAccountingConnectors],
	);

	return { dataSource };
};

export default useAccountingConnectorsDataSource;
