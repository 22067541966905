import { gql } from '@apollo/client';

import { ModuleCodeEnum } from '../../types/widget';

// QUERIES

export type FetchModulesType = {
	modules: {
		code: ModuleCodeEnum;
	}[];
};

export const FETCH_MODULES = gql`
	query Modules {
		modules {
			code
		}
	}
`;
