import { gql } from '@apollo/client';

import { AnalyticalType } from '../types/analytical';

export const ANALYTICAL_DIMENSION_FIELDS = `
	id
	code
	name
`;

// QUERIES

export type FetchAnalyticalDimensionsByCustomerFileType = {
	analyticalDimensionsByCustomerFile: {
		count: number;
		items: AnalyticalType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_ANALYTICAL_DIMENSIONS_BY_CUSTOMER_FILE = gql`
	query AnalyticalDimensionsByCustomerFile($customerFileId: ID!, $search: String, $page: Page!, $orderBy: OrderBy) {
		analyticalDimensionsByCustomerFile(
			customerFileId: $customerFileId
			search: $search
			page: $page
			orderBy: $orderBy
		) {
			count
			items {
				${ANALYTICAL_DIMENSION_FIELDS}
			}
			limit
			offset
		}
	}
`;
