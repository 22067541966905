import { Chip, TableColumnType } from '@elipssolution/harfang';

import CustomerFile from './customerFile/CustomerFile';
import useSettingsCustomerFilesDataSource from './hooks/useSettingsCustomerFilesDataSource';
import LinkIcon from '../../../components/LinkIcon';
import SettingsTable from '../../../components/SettingsTable';
import { useSettingsDialog } from '../../../hooks/useSettingsDialog';
import { CustomerFileWithDomainType } from '../../../types/customerFile';

type TableCustomerFileType = Pick<CustomerFileWithDomainType, 'id' | 'code' | 'name' | 'domain'>;

const columns: TableColumnType<TableCustomerFileType>[] = [
	{
		field: 'code',
		flexGrow: 0,
		key: 'code',
		sortable: true,
		title: 'Code',
		width: 100,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Nom du dossier',
		width: 200,
	},
	{
		key: 'chip',
		flexGrow: 0,
		render: ({ domain: { isDefault, name } }) => <Chip label={name} color={isDefault ? 'info' : 'default'} />,
		width: 110,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

const CustomerFileTableSection = () => {
	const { push } = useSettingsDialog();

	const { dataSource } = useSettingsCustomerFilesDataSource();

	const openCustomerFileForm = (customerFile?: TableCustomerFileType) =>
		push(<CustomerFile customerFileId={customerFile?.id} />);

	return (
		<SettingsTable<TableCustomerFileType>
			addButtonLabel="Ajouter un dossier"
			dataSource={dataSource}
			onAddButtonClick={openCustomerFileForm}
			onRowClick={openCustomerFileForm}
			tableColumns={columns}
			title="Dossiers"
		/>
	);
};

export default CustomerFileTableSection;
