import { useLazyQuery } from '@apollo/client';
import { TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import { CustomerFileWithDomainType } from '../../../../types/customerFile';
import DataSourceResultFormatType from '../../../../types/dataSource';
import {
	FETCH_ARCHIVED_CUSTOMER_FILES_WITH_DOMAIN,
	FetchArchivedCustomerFilesWithDomainType,
} from '../../../api/customerFile';

const useArchivedCustomerFileDataSource = () => {
	const [fetchArchivedCustomerFiles] = useLazyQuery<FetchArchivedCustomerFilesWithDomainType>(
		FETCH_ARCHIVED_CUSTOMER_FILES_WITH_DOMAIN,
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<CustomerFileWithDomainType>,
		): Promise<Pick<DataSourceResultFormatType<CustomerFileWithDomainType>, 'count' | 'items'>> => {
			const { field, order } = orderBy ?? {};

			const { data, error } = await fetchArchivedCustomerFiles({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: { limit, offset },
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				archivedCustomerFiles: { count = 0, items = [] },
			} = data ?? {
				archivedCustomerFiles: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchArchivedCustomerFiles],
	);

	return { dataSource };
};

export default useArchivedCustomerFileDataSource;
