import { useQuery } from '@apollo/client';
import { SettingsGroup, SettingsItemNavigation } from '@elipssolution/harfang';
import { useMemo } from 'react';

import Contacts from './Contacts';
import CustomerFileForm from './CustomerFileForm';
import Modules from './Modules';
import PermissionWall from '../../../../components/PermissionWall';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../types/customerFile';
import { PermissionEnum } from '../../../../types/permission';
import {
	FETCH_SETTINGS_CUSTOMER_FILE,
	FetchSettingsCustomerFileResultType,
	FetchSettingsCustomerFileVariablesType,
} from '../../../api/customerFile';

type CustomerFileProps = {
	customerFileId?: CustomerFileType['id'];
};

const CustomerFile = ({ customerFileId }: CustomerFileProps) => {
	const { replace, push } = useSettingsDialog();

	const { data: settingsCustomerFileFetchedData } = useQuery<
		FetchSettingsCustomerFileResultType,
		FetchSettingsCustomerFileVariablesType
	>(FETCH_SETTINGS_CUSTOMER_FILE, {
		variables: {
			// Type assertion needed because the type inference does not take account of the skip property
			id: customerFileId as string,
		},
		skip: !customerFileId,
	});

	const customerFile = useMemo(() => {
		const { customerFile: fetchedCustomerFile } = settingsCustomerFileFetchedData ?? {};

		if (!fetchedCustomerFile) {
			return fetchedCustomerFile;
		}

		return {
			...fetchedCustomerFile,
			connector: fetchedCustomerFile.connector ?? {
				code: null,
				name: 'Aucun',
			},
		};
	}, [settingsCustomerFileFetchedData]);
	const { name } = customerFile ?? {};

	const navigateToModulesPage = () => customerFileId && push(<Modules customerFileId={customerFileId} />);

	const navigateToContactsPage = () => customerFileId && push(<Contacts customerFileId={customerFileId} />);

	const handleCustomerFileChange = ({ id: newCustomerFileId }: FetchSettingsCustomerFileResultType['customerFile']) =>
		replace(<CustomerFile customerFileId={newCustomerFileId} />);

	return (
		<SettingsDialogPage title={name ?? "Ajout d'un dossier client"}>
			<CustomerFileForm customerFile={customerFile} onCustomerFileChange={handleCustomerFileChange} />

			{customerFileId && (
				<SettingsGroup>
					<PermissionWall
						permissionCodes={[
							PermissionEnum.DOCUMENT_SETTING_STORAGE,
							PermissionEnum.DOCUMENT_SETTING_TAG,
							PermissionEnum.QUICKENTRY_SETTING,
							PermissionEnum.ACCOUNTING_SETTING,
						]}
					>
						<SettingsItemNavigation
							description="Configuration des modules du dossier"
							label="Modules"
							onClick={navigateToModulesPage}
						/>
					</PermissionWall>
					<SettingsItemNavigation
						description="Configuration des contacts du dossier"
						label="Contacts"
						onClick={navigateToContactsPage}
					/>
				</SettingsGroup>
			)}
		</SettingsDialogPage>
	);
};

export default CustomerFile;
