import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import {
	FETCH_SETTINGS_CUSTOMER_FILES,
	FetchSettingsCustomerFilesResultType,
	FetchSettingsCustomerFilesVariablesType,
} from '../../../api/customerFile';

const useSettingsCustomerFilesDataSource = () => {
	const [fetchSettingsCustomerFiles] = useLazyQuery<
		FetchSettingsCustomerFilesResultType,
		FetchSettingsCustomerFilesVariablesType
	>(FETCH_SETTINGS_CUSTOMER_FILES);

	const dataSource = useCallback(
		async (
			limit: FetchSettingsCustomerFilesVariablesType['page']['limit'],
			offset: FetchSettingsCustomerFilesVariablesType['page']['offset'],
			search?: FetchSettingsCustomerFilesVariablesType['search'],
			orderBy?: FetchSettingsCustomerFilesVariablesType['orderBy'],
		): Promise<FetchSettingsCustomerFilesResultType['customerFiles']> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchSettingsCustomerFiles({
				variables: {
					...(field &&
						order && {
							orderBy: { field, order },
						}),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				customerFiles: { count = 0, items = [] },
			} = data ?? {
				customerFiles: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchSettingsCustomerFiles],
	);

	return { dataSource };
};

export default useSettingsCustomerFilesDataSource;
