import { AccountingConnectorType } from '../../../src/types/accountingConnector';

const connectorMaxNameLengthMapper = new Map([
	['agiris-axe', 30],
	['agiris-fidorg', 30],
	['cegid', 35],
]);

export const getConnectorMaxNameLength = (connectorCode: AccountingConnectorType['code']) =>
	connectorMaxNameLengthMapper.get(connectorCode);
