import { useMutation } from '@apollo/client';
import { SettingsGroup, SettingsItemSwitch, SettingsItemTextField } from '@elipssolution/harfang';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { EDIT_ALERT, EditAlertType } from '../../api/alert';
import useSettingsAlert from '../../hooks/useSettingsAlert';

type FormType = {
	id?: string;
	isActive: boolean;
	content: string;
};

let formChangeTimeout: ReturnType<typeof setTimeout>;

const AlertSection = () => {
	const {
		control,
		formState: { isDirty },
		reset,
		watch,
	} = useForm<FormType>();

	const formAlertId = watch('id');
	const formAlertContent = watch('content');
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const formIsAlertActive = watch('isActive');

	useSettingsAlert({
		onCompleted: ({ alert: alertData }) => {
			const { id, content, isActive } = alertData;

			reset({ id, content, isActive });
		},
	});

	const [editAlert] = useMutation<EditAlertType>(EDIT_ALERT, {
		onCompleted: ({ editAlert: { id } }) =>
			reset({
				id,
				content: formAlertContent,
				isActive: formIsAlertActive,
			}),
		refetchQueries: ['Alert'],
	});

	const handleFieldChange = useCallback(
		() =>
			editAlert({
				variables: {
					editAlertInput: {
						id: formAlertId,
						content: formAlertContent,
						isActive: formIsAlertActive,
					},
				},
			}),
		[editAlert, formAlertContent, formAlertId, formIsAlertActive],
	);

	useEffect(() => {
		if (!isDirty) return;

		clearTimeout(formChangeTimeout);

		formChangeTimeout = setTimeout(() => {
			handleFieldChange().catch((error) => {
				throw error;
			});
		}, 300);
	}, [handleFieldChange, formAlertContent, formIsAlertActive, isDirty]);

	return (
		<SettingsGroup label="Alerte" actionsWidth="80%">
			<Controller
				control={control}
				name="isActive"
				render={({ field: { onChange, value } }) => {
					const handleChange = (checked: boolean) => onChange(checked);

					return <SettingsItemSwitch checked={value} label="Activer la bannière d'alerte" onChange={handleChange} />;
				}}
			/>
			<Controller
				control={control}
				name="content"
				render={({ field }) => <SettingsItemTextField {...field} label="Contenu de l'alerte" multiline />}
			/>
		</SettingsGroup>
	);
};

export default AlertSection;
