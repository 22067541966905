import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { FETCH_SUBACCOUNTS_BY_ACCOUNT, FetchSubaccountsByAccountType } from '../api/account';
import { AccountType } from '../types/account';

const useSubaccountsByAccountDataSource = ({ accountId }: { accountId?: AccountType['id'] }) => {
	const [fetchSubaccountsByAccount] = useLazyQuery<FetchSubaccountsByAccountType>(FETCH_SUBACCOUNTS_BY_ACCOUNT);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: AccountType[];
		}> => {
			if (!accountId) {
				return {
					count: 0,
					items: [],
				};
			}

			const { data, error: queryError } = await fetchSubaccountsByAccount({
				variables: {
					page: {
						limit,
						offset,
					},
					accountId,
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				subaccountsByAccount: { count = 0, items = [] },
			} = data ?? {
				subaccountsByAccount: {},
			};

			return {
				count,
				items,
			};
		},
		[accountId, fetchSubaccountsByAccount],
	);

	return { dataSource };
};

export default useSubaccountsByAccountDataSource;
