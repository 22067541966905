import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import {
	FETCH_ANALYTICAL_SECTIONS_BY_ANALYTICAL_DIMENSION,
	FetchAnalyticalSectionsByAnalyticalDimensionType,
} from '../api/analyticalSection';
import { AnalyticalDimensionType } from '../types/analyticalDimension';
import { AnalyticalSectionType } from '../types/analyticalSection';

const useAnalyticalSectionsByAnalyticalDimensionDataSource = ({
	analyticalDimensionId,
}: {
	analyticalDimensionId?: AnalyticalDimensionType['id'];
}) => {
	const [fetchAnalyticalSectionsByAnalyticalDimension] = useLazyQuery<FetchAnalyticalSectionsByAnalyticalDimensionType>(
		FETCH_ANALYTICAL_SECTIONS_BY_ANALYTICAL_DIMENSION,
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: AnalyticalSectionType[];
		}> => {
			if (!analyticalDimensionId) {
				return {
					count: 0,
					items: [],
				};
			}

			const { data, error: queryError } = await fetchAnalyticalSectionsByAnalyticalDimension({
				variables: {
					analyticalDimensionId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				analyticalSectionsByAnalyticalDimension: { count = 0, items = [] },
			} = data ?? {
				analyticalSectionsByAnalyticalDimension: {},
			};

			return {
				count,
				items,
			};
		},
		[analyticalDimensionId, fetchAnalyticalSectionsByAnalyticalDimension],
	);

	return { dataSource };
};

export default useAnalyticalSectionsByAnalyticalDimensionDataSource;
