import { gql } from '@apollo/client';

import { SessionType } from '../types/session';

// QUERY

export type FetchSessionType = {
	session: SessionType;
};

export const FETCH_SESSION = gql`
	query Session {
		session {
			user {
				id
				lastName
				firstName
				email
				type
				isInternalAdministrator
				hasSettingsAccess
			}
			permissions {
				code
				value
			}
			customerFile {
				id
				code
				name
				connectorCode
				minimumEntryDate
			}
		}
	}
`;
