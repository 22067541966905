import { useLazyQuery } from '@apollo/client';
import {
	Autocomplete,
	CurrencyFilterDefinitionType,
	DateRangePicker,
	TableOrderByType,
	TreeTableInstance,
} from '@elipssolution/harfang';
import { Divider, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';

import { useSession } from '../../../../src/components/SessionProvider';
import { FiscalYearType } from '../../../../types/fiscalYear';
import {
	FETCH_ANALYTICAL_DIMENSIONS_BY_CUSTOMER_FILE,
	FetchAnalyticalDimensionsByCustomerFileType,
} from '../../../quickentry/api/analyticalDimension';
import { AnalyticalType } from '../../../quickentry/types/analytical';
import AccountingTypeSelector from '../../components/AccountingTypeSelector';
import FiscalYearAutocomplete from '../../components/FiscalYearAutocomplete';
import { AccountingType } from '../../types/accounting';
import { SwitchOperatorType } from '../../types/table';

const filters: FilterType[] = [
	{
		id: 'ne',
		label: 'Solde différent de 0',
	},
	{
		id: 'lt',
		label: 'Solde négatif',
	},
	{
		id: 'gt',
		label: 'Solde positif',
	},
];

type FilterType = {
	id: SwitchOperatorType;
	label: string;
};

type AccountingTableTogglesType = {
	accountingType: AccountingType;
	selectedFiscalYear?: FiscalYearType;
	selectedAnalyticalDimension?: AnalyticalType;
	selectedDateRange?: [Date, Date];
	balanceFilter?: CurrencyFilterDefinitionType['value'];
	isGroupedByAccount: boolean;
	tableInstance: TreeTableInstance;
	onAccountingTypeChange: (accountingType: AccountingType) => void;
	onAnalyticalDimensionChange: (analyticalDiemension?: AnalyticalType) => void;
	onFiscalYearChange: (fiscalYear?: FiscalYearType) => void;
	onDateRangeSelection: (dateRange?: [Date, Date]) => void;
	onBalanceFilterOperatorChange: (operator: FilterType['id']) => void;
	onIsGroupedByAccountChange: (bool: boolean) => void;
};

const AnalyticalSectionsToggles = ({
	tableInstance,
	accountingType,
	balanceFilter,
	selectedFiscalYear,
	selectedAnalyticalDimension,
	selectedDateRange,
	isGroupedByAccount,
	onDateRangeSelection,
	onAnalyticalDimensionChange,
	onFiscalYearChange,
	onAccountingTypeChange,
	onBalanceFilterOperatorChange,
	onIsGroupedByAccountChange,
}: AccountingTableTogglesType) => {
	const { customerFile: sessionCustomerFile } = useSession();
	const { id: sessionCustomerFileId } = sessionCustomerFile || {};
	const { startDate, endDate } = selectedFiscalYear || {};

	const handleBalanceFilterSwitchChange = ({ target: { id: targetId } }: ChangeEvent<HTMLInputElement>) =>
		onBalanceFilterOperatorChange(targetId as FilterType['id']);

	const handleFiscalYearChange = useCallback(
		(fiscalYear?: FiscalYearType) => {
			onFiscalYearChange(fiscalYear);
			onDateRangeSelection(undefined);
		},
		[onDateRangeSelection, onFiscalYearChange],
	);
	const handleIsGroupedByAccountChange = useCallback(() => {
		onIsGroupedByAccountChange(!isGroupedByAccount);
		tableInstance.resetSearch();
		tableInstance.resetFilters();
	}, [isGroupedByAccount, onIsGroupedByAccountChange, tableInstance]);

	const [fetchAnalyticalDimensions] = useLazyQuery<FetchAnalyticalDimensionsByCustomerFileType>(
		FETCH_ANALYTICAL_DIMENSIONS_BY_CUSTOMER_FILE,
		{
			onCompleted: ({ analyticalDimensionsByCustomerFile: { items } }) => {
				if (!selectedAnalyticalDimension) {
					onAnalyticalDimensionChange(items[0]);
				}
			},
		},
	);

	const analyticalDimensionsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<AnalyticalType>,
		): Promise<{
			count: number;
			items: AnalyticalType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error: tagsError } = await fetchAnalyticalDimensions({
				variables: {
					customerFileId: sessionCustomerFileId,
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (tagsError) {
				throw tagsError;
			}

			const {
				analyticalDimensionsByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				analyticalDimensionsByCustomerFile: {},
			};

			return {
				count,
				items,
			};
		},
		[sessionCustomerFileId, fetchAnalyticalDimensions],
	);

	return (
		<Stack gap={2} flex={1}>
			<AccountingTypeSelector onAccountingTypeChange={onAccountingTypeChange} accountingType={accountingType} />
			<Divider />
			<FiscalYearAutocomplete selectedFiscalYear={selectedFiscalYear} onFiscalYearSelection={handleFiscalYearChange} />

			<DateRangePicker
				label="Plage de dates"
				disabled={!selectedFiscalYear}
				min={startDate}
				max={endDate}
				value={selectedDateRange}
				onChange={onDateRangeSelection}
			/>
			<Divider />
			<FormGroup>
				{filters.map(({ id, label }) => (
					<FormControlLabel
						key={id}
						control={
							<Switch id={id} checked={balanceFilter?.[id] === '0.00'} onChange={handleBalanceFilterSwitchChange} />
						}
						label={label}
					/>
				))}
			</FormGroup>
			<Divider />
			<FormControlLabel
				control={<Switch checked={isGroupedByAccount} onChange={handleIsGroupedByAccountChange} />}
				label="Groupé par compte"
			/>

			{!isGroupedByAccount && (
				<Autocomplete<AnalyticalType>
					getOptionLabel={({ name }) => name}
					value={selectedAnalyticalDimension}
					onChange={onAnalyticalDimensionChange}
					dataSource={analyticalDimensionsDataSource}
					label="Axe analytique"
					disableClearable
				/>
			)}
		</Stack>
	);
};

export default AnalyticalSectionsToggles;
