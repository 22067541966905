import { gql } from '@apollo/client';

import { AnalyticalSectionType } from '../types/analyticalSection';

export const ANALYTICAL_SECTION_FIELDS = `
	id
	code
	name
`;

// QUERIES

export type FetchAnalyticalSectionsByAnalyticalDimensionType = {
	analyticalSectionsByAnalyticalDimension: {
		count: number;
		items: AnalyticalSectionType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_ANALYTICAL_SECTIONS_BY_ANALYTICAL_DIMENSION = gql`
	query AnalyticalSectionsByAnalyticalDimension(
		$analyticalDimensionId: ID!
		$orderBy: OrderBy
		$page: Page!
		$search: String
	) {
		analyticalSectionsByAnalyticalDimension(
			analyticalDimensionId: $analyticalDimensionId
			orderBy: $orderBy
			page: $page
			search: $search
		) {
			count
			items {
				${ANALYTICAL_SECTION_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchAnalyticalSectionType = {
	analyticalSection: {
		id: string;
		code: string;
		name: string;
	};
};

export const FETCH_ANALYTICAL_SECTION = gql`
	query AnalyticalSection($id: ID!) {
		analyticalSection(id: $id) {
			${ANALYTICAL_SECTION_FIELDS}
		}
	}
`;
