import { gql } from '@apollo/client';

import DataSourceResultFormatType from '../../types/dataSource';
import { AccountingConnectorType } from '../types/accountingConnector';

// QUERIES

export type FetchAccountingConnectorsResultType = {
	accountingConnectors: DataSourceResultFormatType<AccountingConnectorType>;
};
export type FetchAccountingConnectorsVariablesType = {
	page: { offset: number; limit: number };
	search?: string;
};

export const FETCH_ACCOUNTING_CONNECTORS = gql`
	query AccountingConnectors($page: Page, $search: String) {
		accountingConnectors(page: $page, search: $search) {
			count
			items {
				code
				name
			}
			limit
			offset
		}
	}
`;
