import { useLazyQuery } from '@apollo/client';
import { Autocomplete, CurrencyField, Table, TableColumnType, TextField } from '@elipssolution/harfang';
import { styled } from '@mui/material';
import clsx from 'clsx';
import numeral from 'numeral';
import { forwardRef, useCallback, useMemo } from 'react';
import {
	Control,
	Controller,
	ControllerRenderProps,
	FieldValues,
	UseFormClearErrors,
	UseFormSetError,
	UseFormWatch,
} from 'react-hook-form';

import { useSession } from '../../../../../src/components/SessionProvider';
import { formatCurrency } from '../../../../accounting/utils/formatCurrency';
import { FETCH_SUBACCOUNTS, FetchSubaccountsType } from '../../../api/account';
import useAnalyticalSectionsByAnalyticalDimensionDataSource from '../../../hooks/useAnalyticalSectionsByAnalyticalDimensionDataSource';
import { AccountType, AccountTypeEnum } from '../../../types/account';
import { AnalyticalSectionType } from '../../../types/analyticalSection';
import { DirectionEnum } from '../../../types/settingTemplate';
import { VoucherFormType, VoucherDialogLineType } from '../../../types/voucher';
import { getConnectorMaxNameLength } from '../../../utils/connector';

const LineTextField = styled(TextField)({
	'& > div > input': {
		fontSize: '0.875rem',
		padding: '6px 14px',
	},

	'&.readOnly > div > input': { paddingLeft: 0 },
});

const StyledTable = styled('div')(({ theme: { palette } }) => ({
	'&.warning': {
		'& footer': {
			color: palette.warning.main,
			backgroundColor: `${palette.warning.main}1A`,
		},
	},
}));

const LineCurrencyField = styled(CurrencyField)({
	'& > div > input': {
		fontSize: '0.875rem',
		padding: '6px 14px',
	},

	'&.readOnly > div > input': { paddingLeft: 0 },
});

const DescriptionWrapper = styled('span')({
	display: '-webkit-box',
	WebkitLineClamp: 2,
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
});

const SubaccountAutocomplete = forwardRef<
	HTMLFormElement,
	ControllerRenderProps<FieldValues, string> & {
		accountId: AccountType['id'];
		subaccountPrefix?: string;
		readOnly?: boolean;
	}
>(({ accountId, subaccountPrefix = '', readOnly, ...field }, ref) => {
	const [fetchSubaccounts] = useLazyQuery<FetchSubaccountsType>(FETCH_SUBACCOUNTS);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: AccountType[];
		}> => {
			const { data, error } = await fetchSubaccounts({
				variables: {
					page: {
						limit,
						offset,
					},
					accountId,
					search: `${subaccountPrefix}${search ?? ''}`,
				},
			});

			if (error) {
				throw error;
			}

			const {
				subaccountsByAccount: { count = 0, items = [] },
			} = data ?? {
				subaccountsByAccount: {},
			};

			return { count, items };
		},
		[fetchSubaccounts, accountId, subaccountPrefix],
	);

	return (
		<Autocomplete<AccountType>
			{...field}
			ref={ref}
			dataSource={dataSource}
			getOptionLabel={({ code, name }) => `${code} - ${name}`}
			optionWidth={300}
			readOnly={readOnly}
			sx={{
				width: '100%',
				'.MuiInputBase-root': {
					fontSize: '0.875rem',
					padding: `3.5px 32px 3.5px ${readOnly ? 0 : '8px'} !important`,
				},
				...(readOnly && {
					'.MuiInputBase-input': { paddingLeft: '0 !important' },
				}),
			}}
			disableClearable
		/>
	);
});
SubaccountAutocomplete.displayName = 'SubaccountAutocomplete';

const AnalyticalSectionAutocomplete = forwardRef<
	HTMLFormElement,
	ControllerRenderProps<FieldValues, string> & {
		analyticalDimensionId: string;
		readOnly?: boolean;
	}
>(({ analyticalDimensionId, readOnly, ...field }, ref) => {
	const { dataSource } = useAnalyticalSectionsByAnalyticalDimensionDataSource({
		analyticalDimensionId,
	});

	return (
		<Autocomplete<AnalyticalSectionType>
			{...field}
			ref={ref}
			dataSource={dataSource}
			getOptionLabel={({ code, name }) => `${code} - ${name}`}
			optionWidth={300}
			readOnly={readOnly}
			sx={{
				width: '100%',
				'.MuiInputBase-root': { fontSize: '0.875rem', padding: `3.5px 32px 3.5px ${readOnly ? 0 : '8px'} !important` },
				...(readOnly && {
					'.MuiInputBase-input': { paddingLeft: '0 !important' },
				}),
			}}
			disableClearable
		/>
	);
});
AnalyticalSectionAutocomplete.displayName = 'AnalyticalSectionAutocomplete';

type VoucherLinesSectionProps = {
	analyticalDimensionId?: string;
	analyticalSectionCaption?: string;
	isSubaccountInLines: boolean;
	control: Control<VoucherFormType>;
	watch?: UseFormWatch<VoucherFormType>;
	clearErrors?: UseFormClearErrors<VoucherFormType>;
	setError?: UseFormSetError<VoucherFormType>;
	readOnly?: boolean;
	lines: VoucherDialogLineType[];
	isWarning?: boolean;
};

const VoucherLinesSection = ({
	analyticalDimensionId,
	analyticalSectionCaption,
	isSubaccountInLines,
	control,
	readOnly,
	lines,
	isWarning = false,
	watch,
	clearErrors,
	setError,
}: VoucherLinesSectionProps) => {
	const { customerFile } = useSession();
	const { connectorCode } = customerFile ?? {};

	const formLines = watch?.('lines');

	const totalBalance = !readOnly
		? formLines
				?.reduce(
					(balance, { amount = '0.00', direction }) =>
						direction === DirectionEnum.CREDIT ? balance.add(amount) : balance.subtract(amount),
					numeral(0),
				)
				.value()
		: 0;
	const nameMaxLength = useMemo(
		() => (connectorCode ? getConnectorMaxNameLength(connectorCode) ?? 999 : 999),
		[connectorCode],
	);

	const columns: TableColumnType<VoucherDialogLineType>[] = useMemo(
		() => [
			{
				align: 'center',
				flexGrow: 0,
				key: 'direction',
				render: ({ direction }) => (
					<span
						style={{
							color: direction === DirectionEnum.CREDIT ? 'green' : 'red',
						}}
					>
						{direction.charAt(0).toUpperCase()}
					</span>
				),
				title: '',
				width: 10,
			},
			...(readOnly
				? []
				: [
						{
							field: 'description',
							key: 'description',
							render: ({ description }) => <DescriptionWrapper>{description}</DescriptionWrapper>,
							title: 'Description',
							width: 200,
						} as TableColumnType<VoucherDialogLineType>,
				  ]),
			{
				key: 'name',
				render: (_, index) => (
					<Controller
						control={control}
						name={`lines.${index}.name`}
						render={({ field: { onChange, ...field }, fieldState: { error: fieldError } }) => {
							const handleChange = (value?: string) => {
								if (value && value.length > nameMaxLength) {
									setError?.(`lines.${index}.name`, { message: `Max. ${nameMaxLength} caractères.` });
								} else {
									clearErrors?.(`lines.${index}.name`);
									onChange(value);
								}
							};

							return (
								<LineTextField
									{...field}
									invalid={!!fieldError}
									helperText={fieldError?.message}
									onChange={handleChange}
									readOnly={readOnly}
								/>
							);
						}}
						rules={{
							required: true,
							maxLength: {
								message: `Max. ${nameMaxLength} caractères.`,
								value: nameMaxLength,
							},
						}}
					/>
				),
				title: 'Libellé',
				width: 150,
			},
			{
				align: 'right',
				key: 'amount',
				render: (_, index) => (
					<Controller
						control={control}
						name={`lines.${index}.amount`}
						render={({ field: { value, ...field } }) => (
							<LineCurrencyField
								{...field}
								readOnly={readOnly}
								value={readOnly ? numeral(value).format('0,00') : value}
							/>
						)}
						rules={{ required: true }}
					/>
				),
				title: 'Montant',
				width: 100,
			},
			{
				key: 'account',
				render: ({ account: { code } }) => code,
				title: 'Compte',
				width: 100,
			},
			...(isSubaccountInLines
				? [
						{
							field: 'subaccount',
							key: 'subaccount',
							render: ({ account: { id = '' }, subaccount, type }, index) =>
								type === AccountTypeEnum.SUBACCOUNT && (
									<Controller
										control={control}
										name={`lines.${index}.subaccount`}
										render={({ field }) => (
											<SubaccountAutocomplete {...field} accountId={id} readOnly={readOnly || !!subaccount} />
										)}
										rules={{ required: true }}
									/>
								),
							title: 'Auxiliaire',
							width: 200,
						} as TableColumnType<VoucherDialogLineType>,
				  ]
				: []),
			...(analyticalDimensionId
				? [
						{
							key: 'analyticalSection',
							render: ({ analyticalSection, isAnalyticalEnabled }, index) =>
								isAnalyticalEnabled && (
									<Controller
										control={control}
										name={`lines.${index}.analyticalSection`}
										render={({ field }) => (
											<AnalyticalSectionAutocomplete
												{...field}
												analyticalDimensionId={analyticalDimensionId}
												readOnly={readOnly || !!analyticalSection}
											/>
										)}
									/>
								),
							title: analyticalSectionCaption,
							width: 130,
						} as TableColumnType<VoucherDialogLineType>,
				  ]
				: []),
		],
		[
			clearErrors,
			setError,
			analyticalDimensionId,
			analyticalSectionCaption,
			control,
			isSubaccountInLines,
			nameMaxLength,
			readOnly,
		],
	);

	const dataSource = useCallback(
		() =>
			Promise.resolve({
				count: lines?.length,
				items: lines,
			}),
		[lines],
	);

	return (
		<StyledTable
			className={clsx({
				warning: isWarning,
			})}
		>
			<Table<VoucherDialogLineType>
				columns={columns}
				dataSource={dataSource}
				style={{
					minHeight: 117,
					height: readOnly ? 43 * lines.length + 31 : 43 * (lines.length + 1) + 31,
					maxHeight: 500,
				}}
				footer={
					readOnly
						? undefined
						: [
								{ key: 'name', value: 'SOLDE' },
								{
									key: 'amount',
									value: formatCurrency(`${totalBalance}`),
								},
						  ]
				}
			/>
		</StyledTable>
	);
};

export default VoucherLinesSection;
