import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { CustomerFileType } from '../../../types/customerFile';
import { FETCH_ACCOUNTS_BY_TYPE_AND_CUSTOMER_FILE, FetchAccountsByTypeAndCustomerFileType } from '../api/account';
import { AccountType, AccountTypeEnum } from '../types/account';

type AccountsByTypeAndCustomerFileQueryProps = {
	accountType: AccountTypeEnum;
	customerFileId?: CustomerFileType['id'];
};

const useAccountsByTypeAndCustomerFileDataSource = ({
	accountType,
	customerFileId,
}: AccountsByTypeAndCustomerFileQueryProps) => {
	const [fetchAccountsByTypeAndCustomerFile] = useLazyQuery<FetchAccountsByTypeAndCustomerFileType>(
		FETCH_ACCOUNTS_BY_TYPE_AND_CUSTOMER_FILE,
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: AccountType[];
		}> => {
			if (!customerFileId) {
				return {
					count: 0,
					items: [],
				};
			}

			const { data, error: queryError } = await fetchAccountsByTypeAndCustomerFile({
				variables: {
					type: accountType.toUpperCase(),
					customerFileId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				accountsByTypeAndCustomerFile: { count = 0, items = [] },
			} = data ?? {
				accountsByTypeAndCustomerFile: {},
			};

			return {
				count,
				items,
			};
		},
		[accountType, customerFileId, fetchAccountsByTypeAndCustomerFile],
	);

	return { dataSource };
};

export default useAccountsByTypeAndCustomerFileDataSource;
