import { useLazyQuery } from '@apollo/client';
import { SettingsItemTextField } from '@elipssolution/harfang';
import { useCallback } from 'react';
import { Control, Controller } from 'react-hook-form';

import { CustomerFileWithDomainType } from '../../../../types/customerFile';
import {
	CHECK_CUSTOMER_FILE_CODE_AVAILABILITY,
	CheckCustomerFileCodeAvailabilityType,
} from '../../../api/customerFile';
import { CustomerFileFormType } from '../../../types/settingsCustomerFile';

let availabilityTimer: ReturnType<typeof setTimeout>;

type CustomerFileCodeFieldProps = {
	control: Control<CustomerFileFormType>;
	disabled: boolean;
	initialValue?: string;
};

const CustomerFileCodeField = ({ control, disabled, initialValue }: CustomerFileCodeFieldProps) => {
	const [checkCustomerFileCodeAvailability] = useLazyQuery<CheckCustomerFileCodeAvailabilityType>(
		CHECK_CUSTOMER_FILE_CODE_AVAILABILITY,
	);

	const checkCodeAvailability = useCallback(
		(customerFileCode: CustomerFileWithDomainType['code']): Promise<boolean | string> =>
			new Promise((resolve, reject) => {
				clearTimeout(availabilityTimer);

				if (initialValue === customerFileCode) {
					resolve(true);
					return;
				}

				availabilityTimer = setTimeout(() => {
					checkCustomerFileCodeAvailability({ variables: { code: customerFileCode } })
						.then(({ data }) => {
							const { checkCustomerFileCodeAvailability: isCustomerFileCodeAvailable } = data || {};

							resolve(isCustomerFileCodeAvailable || 'Code dossier déjà utilisé');
						})
						.catch(reject);
				}, 300);
			}),
		[checkCustomerFileCodeAvailability, initialValue],
	);

	return (
		<Controller
			control={control}
			name="code"
			render={({ field, fieldState: { error } }) => (
				<SettingsItemTextField
					{...field}
					description="Code du dossier client."
					disabled={disabled}
					helperText={error?.message ?? ' '}
					invalid={!!error?.message}
					label="Code"
					required
				/>
			)}
			rules={{
				required: 'Champ requis',
				pattern: { message: "Le code dossier ne doit pas contenir d'espaces", value: /^\S+$/ },
				validate: checkCodeAvailability,
			}}
		/>
	);
};

export default CustomerFileCodeField;
