import { useCallback } from 'react';

import DataSourceResultFormatType from '../../../types/dataSource';
import { BankType } from '../types/bank';

const useBankAccountDataSource = ({ bank }: { bank?: BankType }) => {
	const { account: bankAccount } = bank ?? {};

	const dataSource = useCallback(
		async (limit: number, offset: number): Promise<DataSourceResultFormatType<Required<BankType>['account']>> =>
			Promise.resolve(
				bankAccount
					? {
							count: 1,
							items: [bankAccount],
							limit,
							offset,
					  }
					: {
							count: 0,
							items: [],
							limit,
							offset,
					  },
			),
		[bankAccount],
	);

	return { dataSource };
};

export default useBankAccountDataSource;
