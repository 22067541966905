import { useMutation } from '@apollo/client';
import {
	Autocomplete,
	CurrencyField,
	DatePicker,
	Dialog,
	DialogProps,
	Icon,
	IconButton,
	TextField,
	Tooltip,
} from '@elipssolution/harfang';
import { mdiPlus } from '@mdi/js';
import { CircularProgress, Typography, styled, Stack } from '@mui/material';
import { isAfter, isWithinInterval } from 'date-fns';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import AddSupplierFormDialog from './AddSupplierFormDialog';
import useBillExchangeConfigurations from './hooks/useBillExchangeConfigurations';
import useSupplierAccountsDataSource from './hooks/useSupplierAccountsDataSource';
import { useSession } from '../../../../src/components/SessionProvider';
import { DIALOG_CLOSE_DELAY } from '../../../../utils/dialogCloseDelay';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import { findMinMaxDates } from '../../../../utils/fiscalYears';
import {
	UpdateBillExchangeType,
	UPDATE_BILL_EXCHANGE,
	CreateBillExchangeType,
	CREATE_BILL_EXCHANGE,
} from '../../api/billExchange';
import useBank from '../../hooks/useBank';
import useBankAccountDataSource from '../../hooks/useBankAccountDataSource';
import useUnpaginatedFiscalYears from '../../hooks/useUnpaginatedFiscalYears';
import { BankType } from '../../types/bank';
import { BillExchangeType } from '../../types/billExchange';
import { SupplierAccountType } from '../../types/supplierAccounts';
import { getConnectorMaxNameLength } from '../../utils/connector';

const GroupField = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',

	gap: spacing(2),
}));

const LoadingWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: spacing(2),
}));

type FormType = {
	amount: BillExchangeType['amount'];
	documentDate?: BillExchangeType['documentDate'];
	name: BillExchangeType['name'];
	supplier: BillExchangeType['account'] | null;
};

const formDefaultValues = {
	amount: '',
	documentDate: undefined,
	name: '',
	supplier: null,
};

type BillExchangeDialogProps = {
	bankId?: BankType['id'];
	billExchange?: BillExchangeType;
	onBillExchangeEdition: () => void;
	onClose: () => void;
	open: boolean;
};

const BillExchangeDialog = ({
	bankId: selectedBankId,
	billExchange,
	onBillExchangeEdition,
	onClose,
	open,
}: BillExchangeDialogProps) => {
	const { id: billExchangeId, name: billExchangeName, bank: billExchangeBank } = billExchange ?? {};
	const bankId = billExchangeBank?.id ?? selectedBankId;

	const { customerFile } = useSession();
	const { connectorCode } = customerFile ?? {};

	const nameMaxLength = useMemo(
		() => (connectorCode ? getConnectorMaxNameLength(connectorCode) ?? 999 : 999),
		[connectorCode],
	);

	const [isThirdPartyCreationDialogOpen, setIsThirdPartyCreationDialogOpen] = useState(false);
	const [isBillExchangeEditSuccess, setIsBillExchangeEditSuccess] = useState(false);
	const [editBillExchangeError, setEditBillExchangeError] = useState<string>();

	const {
		clearErrors,
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
		setError: setFormError,
		setValue,
	} = useForm<FormType>({
		defaultValues: formDefaultValues,
		mode: 'onBlur',
	});

	const {
		called: isFetchFiscalYearsCalled,
		fiscalYears,
		loading: isFiscalYearsFetchLoading,
	} = useUnpaginatedFiscalYears({ skip: !selectedBankId });

	const { bank, loading: isBankFetchLoading, error: bankFetchError } = useBank({ bankId });

	const { error: configurationsFetchError, loading: isConfigurationsFetchLoading } = useBillExchangeConfigurations({
		bankId,
	});

	const fetchError = useMemo(
		() => bankFetchError || configurationsFetchError,
		[bankFetchError, configurationsFetchError],
	);

	const { dataSource: bankAccountDataSource } = useBankAccountDataSource({ bank });

	const { dataSource: supplierAccountsDataSource } = useSupplierAccountsDataSource();

	const handleClose = useCallback(() => {
		setIsThirdPartyCreationDialogOpen(false);
		setIsBillExchangeEditSuccess(false);
		setEditBillExchangeError(undefined);

		onClose();
		reset(formDefaultValues);
	}, [onClose, reset]);

	const [createBillExchange, { loading: isCreateBillExchangeLoading }] = useMutation<CreateBillExchangeType>(
		CREATE_BILL_EXCHANGE,
		{
			onCompleted: () => {
				setIsBillExchangeEditSuccess(true);
				setTimeout(() => {
					setIsBillExchangeEditSuccess(false);

					handleClose();

					onBillExchangeEdition();
				}, DIALOG_CLOSE_DELAY);
			},
			onError: (mutationError) =>
				setEditBillExchangeError(
					generateErrorInformations({
						error: mutationError,
						resource: 'quickentry_createCheck',
					}).message,
				),
		},
	);

	const [updateBillExchange, { loading: isUpdateBillExchangeLoading }] = useMutation<UpdateBillExchangeType>(
		UPDATE_BILL_EXCHANGE,
		{
			onCompleted: () => {
				setIsBillExchangeEditSuccess(true);
				setTimeout(() => {
					setIsBillExchangeEditSuccess(false);
					handleClose();
					onBillExchangeEdition();
				}, DIALOG_CLOSE_DELAY);
			},
			onError: (mutationError) =>
				setEditBillExchangeError(
					generateErrorInformations({
						error: mutationError,
						resource: 'quickentry_updateCheck',
					}).message,
				),
		},
	);

	const isMandatoryDataLoading = useMemo(
		() => isBankFetchLoading || isConfigurationsFetchLoading || isFiscalYearsFetchLoading,
		[isBankFetchLoading, isConfigurationsFetchLoading, isFiscalYearsFetchLoading],
	);
	const openedFiscalYearsBoundaries = useMemo(() => findMinMaxDates(fiscalYears), [fiscalYears]);
	const { maxDate: fiscalYearMaxDate, minDate: fiscalYearMinDate } = openedFiscalYearsBoundaries ?? {};

	const handleAddSupplierDialogClose = () => setIsThirdPartyCreationDialogOpen(false);
	const handleAddSupplierDialogOpen = () => setIsThirdPartyCreationDialogOpen(true);

	const onSubmit = useCallback(
		({ amount, documentDate, supplier, name }: FormType) => {
			if (!bankId || supplier === null || !documentDate) return null;

			const billExchangeInput = {
				accountId: supplier.id,
				date: documentDate,
				amount,
				bankId,
				name,
			};

			if (billExchangeId) {
				return updateBillExchange({
					variables: {
						updateBillExchangeInput: {
							id: billExchangeId,
							...billExchangeInput,
						},
					},
				});
			}

			return createBillExchange({
				variables: {
					createBillExchangeInput: billExchangeInput,
				},
			});
		},
		[createBillExchange, updateBillExchange, bankId, billExchangeId],
	);

	const mutationButtonLabel = useMemo(() => {
		if (billExchange) return isBillExchangeEditSuccess ? 'LCR modifiée' : 'Modifier la LCR';
		return isBillExchangeEditSuccess ? 'LCR saisie' : 'Saisir';
	}, [billExchange, isBillExchangeEditSuccess]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				label: 'Annuler',
				loading: isCreateBillExchangeLoading || isUpdateBillExchangeLoading,
				onClick: handleClose,
			},
			{
				disabled: !isValid || !isDirty,
				loading: isCreateBillExchangeLoading || isUpdateBillExchangeLoading,
				error: !!editBillExchangeError,
				success: isBillExchangeEditSuccess,
				label: mutationButtonLabel,
				variant: 'contained',
				onClick: handleSubmit(onSubmit),
			},
		],
		[
			isCreateBillExchangeLoading,
			isUpdateBillExchangeLoading,
			handleClose,
			isValid,
			isDirty,
			editBillExchangeError,
			isBillExchangeEditSuccess,
			mutationButtonLabel,
			handleSubmit,
			onSubmit,
		],
	);

	const validateDate = (value?: Date): boolean => {
		if (!value || !isFetchFiscalYearsCalled) {
			clearErrors('documentDate');
			return true;
		}

		const fiscalYear = fiscalYears?.find(({ startDate, endDate }) =>
			isWithinInterval(value, { start: startDate, end: endDate }),
		);

		if (!fiscalYear) {
			setFormError('documentDate', { message: "Aucun exercice en cours n'inclut cette date" });
			return false;
		}
		if (fiscalYear.isClosed) {
			setFormError('documentDate', { message: "L'exercice est clôturé pour cette date" });
			return false;
		}
		clearErrors('documentDate');
		return true;
	};

	useEffect(() => {
		if (billExchange) {
			const { account, amount, ...rest } = billExchange ?? {};

			reset({
				...rest,
				amount: numeral(amount).format('0,00'),
				supplier: account,
			});
		}
	}, [reset, billExchange]);

	// Initialize the date value
	useEffect(() => {
		const todayDate = new Date();

		if (!fiscalYearMinDate || isAfter(todayDate, fiscalYearMinDate)) {
			setValue('documentDate', todayDate);
		} else {
			setValue('documentDate', fiscalYearMinDate);
		}
	}, [setValue, fiscalYearMinDate]);

	return (
		<Dialog
			error={fetchError}
			actionsDialog={actionsDialog}
			maxWidth="xs"
			onClose={handleClose}
			open={open}
			title={billExchangeName ?? 'Saisir un LCR'}
			fullWidth
		>
			{isMandatoryDataLoading ? (
				<LoadingWrapper>
					<CircularProgress size={36} color="inherit" />
					<Typography>Chargement en cours...</Typography>
				</LoadingWrapper>
			) : (
				<>
					<GroupField>
						<Autocomplete<Required<BankType>['account']>
							dataSource={bankAccountDataSource}
							getOptionLabel={({ code, name: accountName }) => `${code} - ${accountName}`}
							label="Banque"
							value={bank?.account}
							readOnly
						/>
						<Controller
							name="documentDate"
							control={control}
							render={({ field, fieldState: { error: fieldError } }) => (
								<DatePicker
									{...field}
									label="Date"
									helperText={fieldError?.message}
									invalid={!!fieldError}
									disableClearable
									max={fiscalYearMaxDate}
									min={fiscalYearMinDate}
								/>
							)}
							rules={{ required: true, validate: validateDate }}
						/>
						<Controller
							name="supplier"
							control={control}
							render={({ field: { onChange, value, ...field } }) => {
								const handleChange = (newValue?: SupplierAccountType) => {
									onChange(newValue);
									!!newValue?.name &&
										setValue('name', newValue?.name, {
											shouldDirty: true,
											shouldValidate: true,
										});
								};

								return (
									<Stack direction="row" gap={1}>
										<Autocomplete<SupplierAccountType>
											{...field}
											label="Fournisseurs"
											dataSource={supplierAccountsDataSource}
											getOptionLabel={({ code, name }) => `${code} - ${name}`}
											onChange={handleChange}
											placeholder="Séléctionnez un fournisseur"
											size="small"
											sx={{
												flex: 1,
											}}
											value={value ?? undefined}
											disableClearable
										/>

										<Tooltip content="Ajouter un fournisseur" placement="left">
											<IconButton onClick={handleAddSupplierDialogOpen}>
												<Icon path={mdiPlus} />
											</IconButton>
										</Tooltip>
									</Stack>
								);
							}}
							rules={{ required: true }}
						/>
						<Controller
							name="name"
							control={control}
							render={({ field: { onChange, ...field }, fieldState: { error: fieldError } }) => {
								const handleChange = (value?: string) => {
									if (value && value.length > nameMaxLength) {
										setFormError('name', { message: `Maximum ${nameMaxLength} caractères.` });
									} else {
										onChange(value);
									}
								};

								return (
									<TextField
										{...field}
										label="Libellé"
										invalid={!!fieldError}
										helperText={fieldError?.message}
										onChange={handleChange}
									/>
								);
							}}
							rules={{
								required: 'Champs requis',
								maxLength: {
									message: `Maximum ${nameMaxLength} caractères.`,
									value: nameMaxLength,
								},
							}}
						/>
						<Controller
							name="amount"
							control={control}
							render={({ field }) => <CurrencyField {...field} label="Montant" />}
							rules={{ required: true }}
						/>
					</GroupField>
					<AddSupplierFormDialog isOpen={isThirdPartyCreationDialogOpen} onClose={handleAddSupplierDialogClose} />
				</>
			)}
		</Dialog>
	);
};

export default BillExchangeDialog;
